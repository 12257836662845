import React, {
  useState,
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useRouter } from 'next/router';
import { Button, IconButton, Popover } from '@sumup/circuit-ui';
import { useAuth } from '@sumup/react-nanoauth';
import { useTranslation } from 'next-i18next';
import { Logout, Profile } from '@sumup/icons';
import { css } from '@emotion/react';
import type { Theme } from '@sumup/design-tokens';

import globalContext from 'components/GlobalContext';
import { STORAGE_KEYS } from 'constants/user';
import DashboardIcon from 'assets/icons/DashboardIcon';
import { formatRedirectUrlFromRouter } from 'services/authClient';
import { isPreviewDeployment } from 'server/util/isPreviewDeployment';
import { setJsonToSession, removeJsonFromSession } from 'services/storage';

const mobileOnly = (theme: Theme) => css`
  ${theme.mq.kilo} {
    display: none;
  }
`;

const desktopOnly = (theme: Theme) => css`
  ${theme.mq.untilKilo} {
    display: none;
  }
`;

const HeaderNavigation = () => {
  const auth = useAuth();
  const [isClient, setClient] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const router = useRouter();
  const { authenticated, setAuthenticated } = useContext(globalContext);
  const { t } = useTranslation();

  useEffect(() => {
    setClient(true);
  }, []);

  const handleSignInClick: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      auth
        .authRedirect({
          redirectRoute: formatRedirectUrlFromRouter(router),
          ...(isPreviewDeployment()
            ? { sourceOrigin: window?.location?.origin }
            : {}),
        })
        .catch(() => {});
    },
    [auth, router],
  );

  const handleSignOutClick: MouseEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
      if (window?.embedded_svc) {
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call */
        window.embedded_svc.liveAgentAPI.clearSession();
      }
      setJsonToSession(STORAGE_KEYS.HAS_LOGGED_IN, false);
      removeJsonFromSession(STORAGE_KEYS.USER);
      setAuthenticated(false);
      auth
        .endOIDCSession({
          redirectRoute: formatRedirectUrlFromRouter(router),
          ...(isPreviewDeployment()
            ? { sourceOrigin: window?.location?.origin }
            : {}),
        })
        .catch(() => {});
    },
    [auth, router],
  );

  const actions = [
    {
      href: t('header.dashboard.url'),
      target: '_blank',
      rel: 'noreferrer',
      icon: DashboardIcon,
      children: t('header.dashboard.name'),
    },
    {
      onClick: handleSignOutClick,
      icon: Logout,
      children: t('header.logout'),
      destructive: true,
    },
  ];

  if (!isClient || !authenticated) {
    const buttonProps = {
      icon: Profile,
      onClick: handleSignInClick,
      children: t('header.login'),
    };
    return (
      <>
        <IconButton {...buttonProps} css={mobileOnly} />
        <Button {...buttonProps} css={desktopOnly} />
      </>
    );
  }

  return (
    <Popover
      isOpen={isMenuOpened}
      onToggle={setIsMenuOpened}
      placement="bottom-end"
      fallbackPlacements={[]}
      offset={{ mainAxis: 8 }}
      actions={actions}
      component={(props) => (
        <IconButton icon={Profile} {...props}>
          {/* FIXME: Translate */}
          Menu
        </IconButton>
      )}
    />
  );
};

export default HeaderNavigation;
