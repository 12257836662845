import { FC, useEffect, useState, useContext } from 'react';
import { AuthState, useAuth } from '@sumup/react-nanoauth';
import { useRouter } from 'next/router';

import globalContext from 'components/GlobalContext';
import { LOGIN_TRIED } from 'constants/storage';
import { isPreviewDeployment } from 'server/util/isPreviewDeployment';
import { formatRedirectUrlFromRouter } from 'services/authClient';
import {
  checkQueryInRouter,
  cleanUpQueryParam,
  isWebCrawler,
} from 'services/common';
import logger from 'services/logger';
import { getJsonFromSession, setJsonToSession } from 'services/storage';
import { STORAGE_KEYS } from 'src/constants/user';

const AuthPersist: FC = () => {
  const { authenticated, setAuthenticated } = useContext(globalContext);
  const auth = useAuth();
  const router = useRouter();

  const [persistTried, setPersistTried] = useState<boolean>(
    auth.authState === AuthState.Authenticated ||
      !!getJsonFromSession(LOGIN_TRIED) ||
      !!checkQueryInRouter(router, 'login_tried') ||
      !!checkQueryInRouter(router, 'code'),
  );

  useEffect(() => {
    if (checkQueryInRouter(router, 'consent_required')) {
      auth
        .authRedirect({
          redirectRoute: formatRedirectUrlFromRouter(router),
          ...(isPreviewDeployment()
            ? { sourceOrigin: window?.location?.origin }
            : {}),
        })
        .then(() => {})
        .catch(() => {});

      return () => {};
    }
    if (!persistTried && !isWebCrawler()) {
      setPersistTried(true);
      auth
        .authRedirect(
          {
            redirectRoute: formatRedirectUrlFromRouter(router),
            ...(isPreviewDeployment()
              ? { sourceOrigin: window?.location?.origin }
              : {}),
          },
          { prompt: 'none' },
        )
        .catch((err: Error) => logger.error(err, 'Auth redirect failed'));

      return () => {};
    }

    const isRecordedAsAuthenticated =
      auth.authState === AuthState.Authenticated ||
      (auth.authState !== AuthState.Unauthenticated &&
        getJsonFromSession(STORAGE_KEYS.HAS_LOGGED_IN));

    if (authenticated !== isRecordedAsAuthenticated) {
      setAuthenticated(isRecordedAsAuthenticated);
    }
    if (!persistTried) {
      setPersistTried(true);
    }
    setJsonToSession(LOGIN_TRIED, true);
    cleanUpQueryParam('login_tried');

    return () => {};
  }, [auth, authenticated, persistTried, router, setAuthenticated]);

  return <></>;
};

export default AuthPersist;
